import styled from 'styled-components/macro';

import CB from 'commons/Checkbox';

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap; /* 允许项在必要时换行 */
    gap: 6px; 
    align-items: center;
    justify-content: flex-start; 
`;

export const Checkbox = styled(CB)`
  display: inline-block;
  /*自定义label (the container) */
  label {
    display: flex; /* 改为 flex 布局以便内容居中对齐 */
    align-items: center; /* 垂直居中对齐子项 */
    position: relative;
    cursor: pointer;
    font-size: 1em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-width: 30px; /* 修改宽度为最小宽度 */  
    height: 35px;
    margin-bottom: 10px;
    margin-right: 6px;
    line-height: 35px;
    text-align: center;
    padding-left: 4px; 
    padding-right: 4px;
    
    &:hover input ~ .checkmark {
      border: 1px solid ${({ theme }) => theme.colors.grayccc};
    }

    input:focus-visible ~ .checkmark {
      box-sizing: border-box;
      line-height: 30px;
      border: 3px solid ${({ theme }) => theme.colors.grayccc};px;
    }

    /* 按钮按下添加背景框 */
    & input:checked ~ .checkmark {
      background-color: ${({ theme }) => theme.colors.lightyellow};
      color:  ${({ theme }) => theme.colors.black};
      font-weight: normal;
    }

    /* 当点击当时候显示checkmark */
    & input:checked ~ .checkmark:after {
      display: block;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 100%;
      width: 100%;
      z-index: 4; /* 确保 input 覆盖在整个 label 上 */
    }

    /* Create a custom checkbox */
    .checkmark {
      display: inline-block; /* 或者使用 flex, grid 等 */
      padding: 0 6px;
      font-size: 1em;
      font-family:Arial;
      font-weight:normal;
      text-shadow:0px 1px 0px  ${({ theme }) => theme.colors.white};
      box-sizing: border-box;
      line-height: 35px;
      text-align: center;
      color: ${({ theme }) => theme.colors.gray666}; /*菜单字体颜色*/
      border:1px solid  ${({ theme }) => theme.colors.graydcd};
      background-color: ${({ theme }) => theme.colors.grayece};
      //border: 1px solid transparent;
      border-radius:6px;
      min-width: 35px; /* 如果您需要一个最小宽度 */
      height: 35px; /* 如果您希望高度固定 */
      white-space: nowrap; /* 防止内容换行 */
      overflow: hidden; /* 超出部分隐藏，或者使用 'visible' 来显示 */
    }
  }
`;

export const Title = styled.h4`
  float: left;
  display: inline-block;
  width: 100%;
  margin-top: 44px;
`;
