import styled from 'styled-components/macro';

export const BuyButton = styled.button`
  background-color: ${({theme}) => theme.colors.black};
  color: ${({theme}) => theme.colors.primary};
  padding: 15px 0;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
  border: 0;
  font-weight: bold;
  font-size: 40px;
  font-style: italic;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  transition: background-color 0.2s;

  &:focus-visible {
    outline: 3px solid ${({theme}) => theme.colors.darkgreen};
  }
`;

interface IImage {
    alt: string;
}

export const Image = styled.img<IImage>``;

interface IContainer {
    sku: number | string;
}

export const Container = styled.div<IContainer>`
  position: relative;
  text-align: center;
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 30px;
  background-color: ${({theme}) => theme.colors.white};
  border-top: 1px solid  ${({theme}) => theme.colors.black};
  border-bottom: 1px solid  ${({theme}) => theme.colors.black};
  cursor: default;
  outline: none;
  padding: 10px;

  &:focus-visible {
    outline: 3px solid ${({theme}) => theme.colors.secondary};
  }

  &:nth-of-type(2n) {
    border-left: 1px solid  ${({theme}) => theme.colors.black};
  }
  &:nth-of-type(2n):last-child {
    border-right: none; 
  }
  &:last-child {
    border-right: 1px solid  ${({theme}) => theme.colors.black};
  }

  /* mobile  */
  ${Image} {
    width: 100%;
    height: 228px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px solid ${({theme}) => theme.colors.black};
    border-radius: 2px;

    ::before {
      content: '';
      display: block;
      position: absolute;
      background: #eee;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    /* pc */
    @media only screen and (min-width: ${({theme: {breakpoints}}) => breakpoints.tablet}) {
      height: 320px;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;

    }
  }

  &:hover {
    ${Image} {

    }

    ${BuyButton} {
      background-color: ${({theme}) => theme.colors.darkgreen};
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      color: ${({theme}) => theme.colors.black};
    }
  }
`;

export const Stopper = styled.div`
  position: absolute;
  color: ${({theme}) => theme.colors.black};
  top: 10px;
  right: 10px;
  padding: 4px 6px 4px 6px;
  font-size: .8em;
  background-color: ${({theme}) => theme.colors.darkgreen};
  border-radius: 2px;
  cursor: default;
  z-index: 1;
`;

export const Title = styled.p`
  position: relative;
  padding: 0 20px;
  height: 45px;

  &::before {
    content: '';
    width: 20px;
    height: 2px;
    background-color: ${({theme}) => theme.colors.secondary};
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -10px;
  }
`;

export const Price = styled.div`
  height: 60px;
  .val {
    b {
      font-size: 1.6em;
      margin-left: 5px;
    }
  }
`;

export const Val = styled.p`
  margin: 0;
  color:  ${({ theme }) => theme.colors.red};
  b {
    font-size: 2em;
    font-weight: bold;
    margin-left: 5px;
  }
  span{
    font-size: 1.1em;
    font-weight: normal;
    font-style: italic;
  }
`;

export const Discount = styled.p`
  margin: 0;
  color: #9c9b9b;
`;
