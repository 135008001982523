export function randomStr(length:number):string{let result:string='';const characters:string='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';const charactersLength:number=characters.length;for(let i=0;i<length;i++){result+=characters.charAt(Math.floor(Math.random()*charactersLength))}return result}
export function detectPlatform(){const ua=navigator.userAgent.toLowerCase();if(ua.includes("micromessenger")){return"weixin"}const isDesktop=window.screen.width>1024;const system={win:navigator.platform.indexOf("Win")===0,mac:navigator.platform.indexOf("Mac")===0,x11:navigator.platform==="X11"||navigator.platform.indexOf("Linux")===0,ipad:!!navigator.userAgent.match(/iPad/i),};if((system.win||system.mac||system.x11||system.ipad)&&isDesktop){return"pc"}else{return"mobile"}}

export function  getQueryParam (url:string,param:string) {
    const pattern = new RegExp('[?&]' + param + '=([^&#]*)', 'i');
    const queryString = url.match(pattern);
    return queryString ? queryString[1] : null;
};

export function makeGoodsStr(arr:any[]){
    let str:string = '';
    if(arr.length){
        arr.map((item,idx)=>{
            str += `${idx+1}-${item.title}(${item.style}) `;
        });
        return str;
    }else{
        return;
    };
};

