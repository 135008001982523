export interface StyleClosetTheme {
    colors: { [key in keyof typeof colors]: string };
    breakpoints: { [key in keyof typeof breakpoints]: string };
    spaces: { [key in keyof typeof spaces]: string; };
}

const colors = {
    primary: '#C9FF19',
    red: '#ff4500',
    darkgreen: '#ff8806',
    black: '#2D2926',
    gray666: '#666666',
    darkrayy: '#97999b',
    grayccc: '#cccccc',
    graydcd: '#dcdcdc',
    grayece: '#ececec',
    lightgrey: '#f4f4f4',
    secondary: '#f0f0f0',
    white: '#ffffff',
    antiquewhite: '#ffe3d5',
    lightyellow: '#ffa703'
};

const breakpoints = {
    mobile: '480px', tablet: '768px', desktop: '1025px',
};
const spaces = {
    small: '8px', medium: '16px', large: '24px'
};


const theme: StyleClosetTheme = {
    colors, breakpoints, spaces
};


export {theme};
