import {useEffect} from 'react';
import {BrowserRouter as Route} from 'react-router-dom';
import Loader from 'components/Loader';
import {BannerCorner, BannerFooter} from 'components/Banner';
// import Login from 'components/Login';
import Filter from 'components/Filter';
import Products from 'components/Products';
import Cart from 'components/Cart';

import {ModalProvider} from 'contexts/modal-context';
import {AuthProvider} from 'contexts/auth-context/index';
import {useProducts} from 'contexts/products-context';

import * as S from './style';
function App() {
    const {isFetching, products, fetchProducts} = useProducts();

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    return (
        <Route>
        <ModalProvider>
            <AuthProvider>
                <S.Container>
                    {isFetching && <Loader/>}
                    <BannerCorner/>
                    <S.TwoColumnGrid>
                        <S.Side>
                            <Filter/>
                            <BannerFooter/>
                        </S.Side>
                        <S.Main>
                            <S.MainHeader>
                                <p>找到 {products?.length} 件货</p>
                            </S.MainHeader>
                            <Products products={products}/>
                        </S.Main>
                    </S.TwoColumnGrid>
                    <Cart/>
                </S.Container>
            </AuthProvider>
        </ModalProvider>
    </Route>
    );
}
export default App;
