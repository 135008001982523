import styled from 'styled-components/macro';
export const CartButton = styled.button`
  border: 0;
  padding: 0;
  width: 50px;
  height: 50px;
  color: ${({ theme }) => theme.colors.lightyellow};
  background-color: ${({ theme }) => theme.colors.black};
  text-align: center;
  line-height: 50px;
  position: absolute;
  top:0;
  left: 0;
  cursor: pointer;
  z-index: 2;
  
  span{
    font-size: 24px;
    font-weight: normal;
  }
  &:focus-visible {
    outline: 3px solid ${({ theme }) => theme.colors.red};
  }

  &:hover {
    filter: brightness(85%);
  }
`;

interface IContainer {
  isOpen: boolean;
}
export const Container = styled.div<IContainer>`
  position: fixed;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray};
  box-sizing: border-box;
  z-index: 99;

  transition: right 0.2s;

  /*mobile*/
  ${CartButton} {
    left: ${({ isOpen }) => (isOpen ? '0' : '-50px')};
    background-color: ${({ theme, isOpen }) =>
      isOpen ? theme.colors.black : theme.colors.darkgreen};
  }
  
  /* pc */
  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    width: 450px;
    right: ${({ isOpen }) => (isOpen ? '0' : '-450px')};
    
    ${CartButton} {
      left: -50px;
      
    }
  }
`;

interface ICartIcon {
  large?: boolean;
}
export const CartIcon = styled.div<ICartIcon>`
  width: ${({ large }) => (large ? '60px' : '50px')};
  height: ${({ large }) => (large ? '60px' : '50px')};
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  //background-image: url('../../static/cart-icon.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-size: 50%;
`;

export const CartQuantity = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  color: #ffffff;
  font-weight: normal;
  font-size: 0.7em;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.red};
  position: absolute;
  bottom: 0;
  right: 5px;
`;

export const CartContent = styled.div`
  height: 100%;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.colors.lightgrey};

  /* MAC scrollbar para desktop*/
  @media screen and (min-width: 640px) {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 10px;
      background-color: rgba(0, 0, 0, 0.2);
      padding: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #0c0b10;
    }
  }
`;

export const CartContentHeader = styled.div`
  box-sizing: border-box;
  text-align: center;
  padding: 28px 0;
  background-color: ${({ theme }) => theme.colors.darkgreen};
`;

export const HeaderTitle = styled.span`
  color:${({ theme }) => theme.colors.black};
  font-weight: normal;
  font-size: 1.4em;
  vertical-align: middle;
`;

export const Sub = styled.p`
  width: 20%;
  color: #5b5a5e;
  vertical-align: middle;
  display: inline-block;
`;

export const SubPrice = styled.div`
  width: 80%;
  text-align: right;
  color: ${({ theme }) => theme.colors.gray666};
  vertical-align: middle;
  display: inline-block;
`;

export const SubPriceValue = styled.p`
  color: ${({ theme }) => theme.colors.red};
  font-size: 22px;
  margin: 0;
  font-weight: normal;;
`;

export const SubPriceInstallment = styled.p`
  margin: 0;

  span {
    display: inline-block;
    border: 1px solid rgba(210, 191, 134, 0.5);
    background-color: rgba(253, 235, 144, 0.5);
    font-style: italic;
    font-weight: 300;
    color: #c19a26;
    padding:2px;
    border-radius: 50px;
    position: relative; 
    overflow: hidden; 
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -100%; 
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.6);
      transform: skewX(-20deg);
      animation: shine 2s 4;
    }
  }

  @keyframes shine {
    0% {
      left: -100%; /* 从左侧开始 */
    }
    50% {
      left: 100%; /* 移动到右侧 */
    }
    100% {
      left: 100%; /* 保持在右侧 */
    }
  }
`;

export const CheckoutButton = styled.button`
  width: 50%;
  border: 0;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.red};
  text-align: center;
  padding: 15px 0;
  margin-top: 40px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;

  &:focus-visible {
    outline: 3px solid ${({ theme }) => theme.colors.secondary};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.black};
  }
  
  &:disabled {
    background-color: ${({ theme }) => theme.colors.grayccc};
  }
`;

export const AddAddrButton = styled.button`
  width: 50%;
  border: 0;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  background-color: #07C160;
  text-align: center;
  padding: 15px 0;
  margin-top: 40px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;

  &:focus-visible {
    outline: 3px solid ${({ theme }) => theme.colors.darkgreen};
  }

  &:hover {
    background-color:  ${({ theme }) => theme.colors.black};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grayccc};
  }
`;

export const CartFooter = styled.div`
  box-sizing: border-box;
  padding: 5%;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.white};
  border-top:1px solid  ${({ theme }) => theme.colors.grayccc};
  }
`;
