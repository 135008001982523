import styled from 'styled-components/macro';
export const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding:10px;
  border-top:1px solid ${({ theme }) => theme.colors.graydcd};
  background-color: ${({ theme }) => theme.colors.lightgrey};
  text-align: center;
  left: 0;
  bottom: 0;
  z-index: 94;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
  }

  div > p,
  div > a {
    align-self: flex-start;
  }

  a, p {
    color: ${({ theme }) => theme.colors.graydcd};
    font-size: 10px;
    text-decoration: none;
    margin: 0; 
  }
`;

export const Logo = styled.img`
  width: 100px;
  margin-right: 20px; /* Logo右侧的间隔 */
  border: 2px solid ${({ theme }) => theme.colors.lightgrey};
  border-radius: 6px;


  //PC
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100px;
  }
`;