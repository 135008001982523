import * as S from './style';
const logoDuck = `${process.env.REACT_APP_CDN_URL}logo_colourful_duck.png`;

const BannerCorner = () => (
    <S.Container>
      <S.LogoWrapper>
        <S.Logo className="shake" alt="logo" src={logoDuck}/>
          <S.shopName>
              小鸭便利店
          </S.shopName>
      </S.LogoWrapper>
    </S.Container>
);

export default BannerCorner;
