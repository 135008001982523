import React, { FunctionComponent, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ModalBackground, ModalContainer } from './style';

interface ModalProps {
    id: string;
    onClose: () => void;
    children: React.ReactNode;
}

const Modal: FunctionComponent<ModalProps> = ({ id, onClose, children }) => {
    // 优化点1: 将body的选择移到效果外部以减少重复查询
    const body = document.querySelector('body');

    useEffect(() => {
        // 禁用滚动, 并添加了判断防止body为null的情况
        if (body) body.style.overflow = 'hidden';

        // 清除时重新允许滚动
        return () => {
            if (body) body.style.overflow = '';
        };
    }, [body]); // body加入依赖列表

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        // 正确添加事件监听器
        document.addEventListener('keydown', handleKeyDown);

        // 组件卸载时移除事件监听器
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]); // 依赖onClose确保如果onClose变化，重新绑定事件监听器

    const modalRoot = document.getElementById('modal-root');
    if (!modalRoot) return null; // 检查modal-root是否存在

    return ReactDOM.createPortal(
        <ModalBackground onClick={onClose}>
            <ModalContainer onClick={(e) => e.stopPropagation()} id={id}>
                {children}
                <button onClick={onClose}>关闭</button>
            </ModalContainer>
        </ModalBackground>,
        modalRoot // 使用变量代替直接调用getElementById
    );
};

export default Modal;
