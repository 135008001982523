type Product = {
    id: number;
    is_typee: null;
    available_types: string;
    title: string;
    description: string;
    price: number;
    currency_id: string;
    currency_format: string;
    is_free_shipping: number;
    style: string;
    sku: number;
    discount: number;
};

// 定义一个函数来处理 Product 数组，并返回一个字符串数组
export function getSortedUniqueTypes(data: Product[]): string[] {
    const typesSet = new Set<string>();

    data.forEach((product) => {
        // 确保 available_types 是一个有效的 JSON 字符串
        try {
            const types: string[] = JSON.parse(product.available_types);
            types.forEach(type => typesSet.add(type));
        } catch (e) {
            console.error(`Error parsing available_types for product ID ${product.id}:`, e);
        }
    });

    // 返回排序后的数组
    return Array.from(typesSet).sort();
}
