import {KeyboardEvent, useState} from 'react';
import {useCart} from 'contexts/cart-context';
import formatPrice from 'utils/formatPrice';
import {IProduct} from 'models';
import * as S from './style';

interface IProps {
    product: IProduct;
}

const Product = ({product}: IProps) => {
    const {openCart, addProduct} = useCart();
    const {
        sku,
        title,
        price,
        discount,
        currency_id,
        currency_format,
        is_free_shipping,
    } = product;

    // 定义两个状态，一个用于存储当前显示的图片，一个用于标识是否应该显示“-2”图片
    const [imagePath, setImagePath] = useState(`${process.env.PUBLIC_URL}/staticImg/${sku}-1-product.jpg`);
    const [isHovered, setIsHovered] = useState(false);

    // 鼠标悬停和离开的事件处理器
    const handleMouseEnter = () => {
        setIsHovered(true);
        setImagePath(`${process.env.PUBLIC_URL}/staticImg/${sku}-2-product.jpg`);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        setImagePath(`${process.env.PUBLIC_URL}/staticImg/${sku}-1-product.jpg`);
    };

    //鼠标点击
    const handleClick = () => {
        if (isHovered) {
            setImagePath(`${process.env.PUBLIC_URL}/staticImg/${sku}-1-product.jpg`);
            setIsHovered(false);
        } else {
            setImagePath(`${process.env.PUBLIC_URL}/staticImg/${sku}-2-product.jpg`);
            setIsHovered(true);
        }
    };

    //计算价格逻辑
    const formattedPrice = formatPrice(price, currency_id);
    let productDiscount;

    if (discount) {
        const discountPrice = price / discount;

        productDiscount = (
            <S.Discount>
                <span>或 {discount} x</span>
                <b>
                    {currency_format}
                    {formatPrice(discountPrice, currency_id)}
                </b>
            </S.Discount>
        );
    }

    const handleAddProduct = (e:any) => {
        e.stopPropagation();
        addProduct({...product, quantity: 1});
        openCart();
    };

    const handleAddProductWhenEnter = (event: KeyboardEvent) => {
        if (event.key === 'Enter' || event.code === 'Space') {
            addProduct({...product, quantity: 1});
            openCart();
        }
    };

    return (
        <S.Container
            onKeyUp={handleAddProductWhenEnter}
            sku={sku}
            tabIndex={1}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            {is_free_shipping === 0 && <S.Stopper>面售</S.Stopper>}
            <S.Image src={imagePath} alt={title}/>
            <S.Title>{title}</S.Title>
            <S.Price>
                <S.Val>
                    <small>{currency_format}</small>
                    <b>{formattedPrice.substring(0, formattedPrice.length - 3)}</b>
                    <span>{formattedPrice.substring(formattedPrice.length - 3)}</span>
                </S.Val>
                {/*{productDiscount}*/}
            </S.Price>
            <S.BuyButton onClick={handleAddProduct} tabIndex={-1}>
                买!
            </S.BuyButton>
        </S.Container>
    );
};
export default Product;
