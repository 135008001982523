// contexts/modal-context.tsx
import { createContext, useContext, useState, ReactNode, FunctionComponent } from 'react';

interface ModalContextProps {
    children: ReactNode;
}

// 新的IModalContext接口
interface IModalContext {
    modals: { [key: string]: boolean };
    openModal: (id: string) => void;
    closeModal: (id: string) => void;
}

const ModalContext = createContext<IModalContext | undefined>(undefined);

export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
};

// 更新后的ModalProvider
export const ModalProvider: FunctionComponent<ModalContextProps> = ({ children }) => {
    const [modals, setModals] = useState<{ [key: string]: boolean }>({});

    const openModal = (id: string) => setModals((prevModals) => ({ ...prevModals, [id]: true }));
    const closeModal = (id: string) => setModals((prevModals) => ({ ...prevModals, [id]: false }));

    return (
        <ModalContext.Provider value={{ modals, openModal, closeModal }}>
            {children}
        </ModalContext.Provider>
    );
};