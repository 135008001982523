import styled,{ keyframes } from 'styled-components/macro';

const shake = keyframes`
  0% { transform: translate(2px, 0); }
  25% { transform: translate(-2px, 0); }
  50% { transform: translate(0, 2px); }
  75% { transform: translate(0, -2px); }
  100% { transform: translate(2px, 0); }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 10px;

  // pc
  @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {  }
`;
export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  width: 100%;
  background: linear-gradient(172deg, rgb(255, 136, 6) 10%, rgb(33, 33, 33) 30%, rgb(0, 0, 0) 100%);
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
  rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
  rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  padding: 10px;
  overflow: hidden;
  
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
  }
`;

export const Logo = styled.img`
  width: 68px;
  height: 68px;
  &.shake {
    animation: ${shake} 0.3s ease-in-out 10;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {}
`;

export const shopName = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.darkgreen};
  font-weight: 300;
  font-size: 24px;
  margin-top: 10px;
  z-index: 98;

  // PC
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {}
`;
