import * as S from './style';
const logoUrl = `${process.env.PUBLIC_URL}/staticImg/vegetable_logo_gray_80_31.png`
const StarButton = () => (
  <S.Container>
      <S.Logo src = {logoUrl} title="vegetale.png"></S.Logo>
      <div>
      <a href="https://beian.miit.gov.cn/" rel="noreferrer">苏ICP备2023013437号</a>
      <p>版权所有©2024 HI-YAAR.TOP </p>
      </div>
   </S.Container>
);

export default StarButton;
