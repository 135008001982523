import React, { createContext, useContext, useState, useCallback } from 'react';

import { ICustomer, IOrder } from '../../models';

// 定义认证上下文的类型
interface AuthContextType {
    isLoggedIn: boolean;
    userUnionId: string | null;
    customer: ICustomer | null;
    orders: IOrder[];
    login: (code: string) => void;
    logout: () => void;
    setCustomer: (customer: ICustomer | null) => void;
    setOrders: (orders: IOrder[]) => void;
}

// 创建认证上下文对象
const AuthContext = createContext<AuthContextType | null>(null);

// 认证提供者组件
export const AuthProvider: React.FC<{ children?: React.ReactNode }>  = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [userUnionId, setUserUnionId] = useState<string | null>(null);
    const [customer, setCustomer] = useState<ICustomer | null>(null);
    const [orders, setOrders] = useState<IOrder[]>([]);

    const login = useCallback((code: string) => {
        // TODO: 实现登录逻辑，将下面的伪代码替换为实际的逻辑
        // fetchUnionIdFromCode(code).then(unionId => {
        //   setUserUnionId(unionId);
        //   setIsLoggedIn(true);
        //   // ...其他登录成功后的逻辑
        // });
    }, []);

    const logout = useCallback(() => {
        setIsLoggedIn(false);
        setUserUnionId(null);
        setCustomer(null);
        setOrders([]);
    }, []);

    // 上下文提供者包装子组件
    return (
        <AuthContext.Provider value={{ isLoggedIn, userUnionId, login, logout, customer, orders, setCustomer, setOrders }}>
            {children}
        </AuthContext.Provider>
    );
};

// 自定义认证钩子
export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth 必须有一个 AuthProvider');
    }
    return context;
};

// 与后端通信获取 UnionID 的函数 (需要实现)
// async function fetchUnionIdFromCode(code: string): Promise<string | null> {
//     // 实际的登录逻辑，与后端交互
//     throw new Error('fetchUnionIdFromCode function is not implemented');
// }
