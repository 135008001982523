/*src/content/products-content/useProducts.tsx*/
import { useCallback } from 'react';
import { useProductsContext } from './ProductsContextProvider';
import { IProduct } from 'models';
import { getProducts } from 'services/products';

const useProducts = () => {
  const {
    isFetching,
    setIsFetching,
    products,
    setProducts,
    filters,
    setFilters,
  } = useProductsContext();

  /* 拉取数据 */
  const fetchProducts = useCallback(() => {
    setIsFetching(true);
    getProducts().then((products)=> {
      setIsFetching(false);
      console.log('========useProducts.tsx fetchProducts(); products line:22=============');
      console.log(products);
      setProducts(products);
    });
  }, [setIsFetching, setProducts]);


  //关键处理函数
  const filterProducts = (filters: string[]) => {
    console.log("#################product-context/useProducts.tsx line:31 filters#########################");
    console.log(filters);  //正常：[] 或 ['..','..']

    setIsFetching(true);
    getProducts().then((products) => {
      console.log(products); //编辑代码没报红了，但不知道到有没有数据，界面是找.webp图片资源？？？

      setIsFetching(false);
      /*数据*/
      let filteredProducts;

      if (filters && filters.length > 0) {

        filteredProducts = products.filter((p: IProduct) => {
          let availableTypes: string[] = [];
          try {


            //-- MYSQL5.7真实表字段 --
            availableTypes = JSON.parse(p.available_types) as string[];  //TODO

          } catch (e) {
            console.error('解析JSON错误:', e);
            return false;
          }
          // 现在availableTypes是一个数组，可以使用find方法
          return filters.some((filter: string) => availableTypes.includes(filter));
        });

      } else {
        filteredProducts = products;
    }

      setFilters(filters);
      setProducts(filteredProducts);
    });
  };

  return {
    isFetching,
    fetchProducts,
    products,
    filterProducts,
    filters,
  };
};
export default useProducts;
