import {useEffect, useState, useCallback} from 'react';
import axios from 'axios';
import {useLocation,useHistory} from 'react-router-dom';
import * as S from './style';
import * as T from "../../utils/index";
import QRCode from "qrcode.react";
import Cookies from 'js-cookie';
import CartProducts from './CartProducts';
import {useCart} from 'contexts/cart-context';
import {useModal} from "../../contexts/modal-context";
import {ICartProduct, ICartTotal, IProduct} from "../../models";
import {Modal} from "../Modal";
import formatPrice from 'utils/formatPrice';

const CONFIG = {
    appID: process.env.REACT_APP_APP_ID || 'wxe7327d0b2230cf64',
    redirectURI: process.env.REACT_APP_REDIRECT_URI || 'https://hi-yaar.top/wxAuth/',
    API: process.env.REACT_APP_API_URL || 'https://hi-yaar.top/api/',
    CDN: process.env.REACT_APP_CDN_URL || 'https://hiyaar-h5-pic-bucket.s3.us-east-005.backblazeb2.com/'
};

const useQuery = function() {return new URLSearchParams(useLocation().search);};
const Cart = () => {
    let isWxCodeRequested = false;
    //【数据相关】
    const {products, total, isOpen, closeCart, setIsOpen,clearCart} = useCart();
    const [code, setCode] = useState<string | null>(null);
    const [isPaying, setIsPaying] = useState(false);
    const [payResult, setPayResult] = useState(null);
    const [wxConfigData, setWxConfigData] = useState<WxConfigData | null>(null);
    const [isReadyForPayment, setIsReadyForPayment] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [orderInfo, setOrderInfo] = useState<OrderInfo | null>(null);
    const [addressInfo, setAddressInfo] = useState<AddressInfo | null>(null);


    const {openModal, closeModal, modals} = useModal();


//【路由相关】
const history = useHistory();
const refreshPage = () => {history.go(0);};
const query = useQuery();
const _code = query.get('code');

//获取授权code
const getWxCode = useCallback(() => {
       if (!_code && !isPaying) {
            if (T.detectPlatform() === 'weixin') {
                const appID = CONFIG.appID,
                    redirectURI = CONFIG.redirectURI,
                    scope = 'snsapi_base',
                    url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appID}&redirect_uri=${encodeURIComponent(redirectURI)}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`;
                window.location.href = url;
            }
            // 实际的重定向代码...
        } else {
            setCode(_code);
            console.log('授权code已收到:', _code);
        }
}, [_code]);
useEffect(() => {
    if(!isWxCodeRequested){
        isWxCodeRequested = true;
        if(_code) Cookies.set('code',_code);
        console.log('新的 code:', _code);
        getWxCode();
    }
}, [_code, getWxCode]);



    //【模态窗口相关】
    const handleNullOpen = useCallback(() => {
        openModal('carttsx-null');
    }, [openModal]);
    const handleNullClose = () => closeModal('carttsx-null');

    const handleQrClose = () => closeModal('carttsx-qr');

    const isNullModalOpen = modals['carttsx-null'];
    const isQrOpen = modals['carttsx-qr'];
    const isPayResOpen = modals['carttsx-pay'];


    const doPayResOpen = (message:any) => {
        setPayResult(message);
        openModal('carttsx-pay');
    };
    const doPayResClose = () =>{closeModal('carttsx-pay');refreshPage();};

//错误处理
const handleApiError = (error: any, message: string) => {console.error(message, error);};

//【购物车交互】
const handleToggleCart = useCallback(() => {
    if (total.productQuantity === 0 && !isOpen) {
        handleNullOpen();
    } else {
        setIsOpen(!isOpen);
    }
}, [total.productQuantity, isOpen, setIsOpen, handleNullOpen]);

//【没商品自动关闭】
    useEffect(() => {
        if (total.productQuantity === 0 && isOpen) {
            closeCart();
        }
    }, [total.productQuantity, isOpen, closeCart]);

//【支付成功处理】
    // data: {appId: "wxe7327d0b2230cf64", timeStamp: "1712393843", nonceStr: "dPO83m5P6d40uMp7",…}
    // appId: "wxe7327d0b2230cf64"
    // nonceStr: "dPO83m5P6d40uMp7"
    // package: "prepay_id=wx06165723441330f0d9713a201fc9ad0000"
    // paySign: "B1EADD13D017FC83E8B665D87F2D4E69"
    // signType: "MD5"
    // timeStamp: "1712393843"
    // message: "支付初始化成功"
    // success: true
    // success: true
const handlePaymentSuccess = useCallback(async (paymentData:any) => {
    console.log('【进入handlePaymentSuccess】paymentData', paymentData);
    if (paymentData && paymentData.paymentResult && paymentData.paymentResult.success) {
        setIsPaying(false);
        //1. 弹窗支付
        try {
            await callPayWin(paymentData.paymentResult.data);
        } catch (error) {
            console.error("支付弹窗出错", error);
            // alert("支付弹窗出错");
            return;
        };

        // 2. 收货地址处理
        // try {
        //     if (addressInfo){await saveAddrToServer(addressInfo);}
        // } catch (error) {
        //     console.error("保存收货地址出错", error);
        //     // alert("保存收货地址出错");
        //     return;
        // };
    }
}, [orderInfo,addressInfo]);



//【*************** payNow()**************】
interface OrderInfo {
    openid: string;
    items: IProduct[];
    total: number;
};
interface AddressInfo {
    countryName: string;
    provinceName: string;
    cityName: string;
    detailInfo: string;
    postalCode: string;
    userName: string;
    telNumber: string;
};

interface WxConfigData {
    appId: string;
    timeStamp: number;
    nonceStr: string;
    signature: string;
    success: boolean;
}


//页面初始化
const handlePageLoad = async () => {
    let cachedOpenid = Cookies.get('openid');
    if (!cachedOpenid) {
        const authCode = T.getQueryParam(window.location.href, 'code');
        const cacheCode = Cookies.get('code');
        const _code = cacheCode? cacheCode : authCode;
        if (_code) {
            try {
                const freshOpenid = await getOpenId(_code);
                if (freshOpenid) {
                    await saveOpenIdToBackend(freshOpenid);
                    Cookies.set('openid', freshOpenid, { expires: 1 }); //1天
                    cachedOpenid = freshOpenid;
                } else {
                    throw new Error('无法获取有效的OpenID');
                }
            } catch (error) {
                console.error("获取OpenID异常", error);
                // alert("获取OpenID失败，请稍后再试");
                return;
            }
        } else if(!isWxCodeRequested){
            getWxCode();
        }
    }
    // 如果有cachedOpenid，或者新获取了openid，进行后续操作...
    console.log(`使用OpenID ${cachedOpenid} 进行后续操作`);
};

useEffect(() => {
    const handlePageLoadAsync = async () => {
        try {
            await handlePageLoad();
        } catch (error) {
            handleApiError(error, '页面加载处理时发生错误');
        }
    };

    handlePageLoadAsync();
}, []);


//根据wechat（openid）获取customer_id
const getCustomerIdByWechat = async (wechat:string) => {
    try {
        // 使用 URL 路径参数而非查询参数
        const response = await axios.get(`${CONFIG.API}l_customer/get-customer-by-wechat/${wechat}`);
        if (response.status === 200) {
            if (response.data && response.data.data) {
                return response.data.data;
            } else {
                console.error('没有找到 customer ID');
                return null;
            }
        } else {
            console.error('在获取 customer ID时发生错误:', response.status);
            return null;
        }
    } catch (error) {
        console.error('在获取 customer ID时发生异常:', error);
        return null;
    }
};

// 根据tradeNo更新订单状态
const updateOrderStatus = async (tradeNo:string, status:number) => {
    try {
        const response = await axios.post(`${CONFIG.API}l_order/update-status`, {
            code: tradeNo,
            status: status
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        // handleApiError(error, '更新订单状态失败');
        alert("更新订单状态失败");
    }
};

// 删除未支付订单
const markOrderAsCancelledOrDelete = async (tradeNo:string) => {
    try {
        const response = await axios.post(`${CONFIG.API}l_order/delete-order`, {
            tradeNo: tradeNo
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        handleApiError(error, '删除订单失败');
    }
};

//调起支付窗口
 const callPayWin = function(data:any) {
         function onBridgeReady(){
             // @ts-ignore - 忽略因为当前@types/库未定义invoke方法的错误
             WeixinJSBridge.invoke(
                 'getBrandWCPayRequest', {
                     "appId": data.appId,
                     "timeStamp": data.timeStamp,
                     "nonceStr": data.nonceStr,
                     "package": data.package,
                     "signType": data.signType,
                     "paySign": data.paySign
                 },
                 async function(res: any){
                     const _tradeNo = Cookies.get("tradeNo");
                     if(res.err_msg === "get_brand_wcpay_request:ok" ){
                         //更行订单支付status
                         await updateOrderStatus(_tradeNo, 2);

                         //支付成功，购物车清零
                         clearCart();
                         //支付成功，!isReadyForPayment = true
                         setIsReadyForPayment(false);
                         //清空商户单号
                         Cookies.remove('tradeNo');
                         // 清openid
                         Cookies.remove('openid');
                         // 清code
                         Cookies.remove('code');
                         //弹窗
                         doPayResOpen("支付完成");
                     };
                     if(res.err_msg === "get_brand_wcpay_request:fail" ){
                         //清空商户单号
                         Cookies.remove('tradeNo');
                         // 清openid
                         Cookies.remove('openid');
                         // 清code
                         Cookies.remove('code');
                         await markOrderAsCancelledOrDelete(_tradeNo);
                         doPayResOpen("支付失败, 重新下单");
                     };

                     if(res.err_msg === "get_brand_wcpay_request:cancel"){
                         //清空商户单号
                         Cookies.remove('tradeNo');
                         // 清openid
                         Cookies.remove('openid');
                         // 清code
                         Cookies.remove('code');
                         markOrderAsCancelledOrDelete(_tradeNo);
                         doPayResOpen("支付取消, 重新下单");
                     };
                 }
             );
         }
         // @ts-ignore - 忽略因为当前@types/库未定义invoke方法的错误
         if (typeof WeixinJSBridge == "undefined"){
             document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
         } else {onBridgeReady();}
        };
//获取已选商品
const getSelectedProducts = (products:ICartProduct[]): IProduct[] => {
    const selectedProducts = products.map((item:IProduct) => ({
        id: item.id,
        sku: item.sku,
        title: item.title,
        description: item.description,
        style: item.style,
        price: item.price,
        is_free_shipping: item.is_free_shipping,
        available_types: item.available_types,
        currency_format: item.currency_format,
        currency_id: item.currency_format,
        discount: item.discount,
        is_type: item.is_type
    }));

    console.log(`========在函数getSelectedProducts（） selectedProducts========`);
    console.log(selectedProducts);

    return selectedProducts;
};

    //公众号支付 - 1
    const payByOa = async function (code: string,total:ICartTotal,openid:string,goodsStr:string) {
        console.log("===============【进入payByOa（）】========================");
        setIsPaying(true);
        try {
            const _token = Cookies.get('token'); //TOKEN
            const _tradeNo = Cookies.get('tradeNo'); //tradeNo
            // alert(_token);
            const config = _token ? {headers: {'Authorization': `Bearer ${_token}`}}:{};
            const payResponse = await axios.get(`${CONFIG.API}payByOa?code=${code}&amount=${total.totalPrice}&openid=${openid}&goodsStr=${goodsStr}&tradeNo=${_tradeNo}`,config);
            await handlePaymentSuccess(payResponse.data);
        } catch (error) {
            setIsPaying(false);
            handleApiError(error, '支付失败: ');
            throw new Error('支付失败');
        }finally {
            setIsPaying(false);
        }
    };

    //获取openID - 2
    const getOpenId = (codeFromUrl:string) => {
        const cachedOpenid = Cookies.get('openid');
        if (cachedOpenid) {
            console.log('OpenID 已经缓存在Cookie中。');
            return Promise.resolve(cachedOpenid); // 直接返回这个openid
        }

        // 如果没有缓存的openid，发起请求获取
        return axios.get(`${CONFIG.API}get-openid?code=${codeFromUrl}`)
            .then(response => {
                const _openid = response.data.openid;
                if (_openid) {
                    // 缓存openid
                    Cookies.set('openid', _openid);
                    return _openid;
                } else {
                    // 如果响应中没有openid，抛出错误
                    throw new Error('响应中没有OpenID。');
                }
            })
            .catch(error => {
                handleApiError(error, '获取OpenID失败: ');
                throw error; // 继续抛出错误，以便可以在调用函数时处理
            });
    };
    //把openid传后端保存
    const saveOpenIdToBackend = async (openid:string) => {
        console.log("===============【进入saveOpenIdToBackend（）】========================");
        try {
            const response = await axios.post(`${CONFIG.API}l_customer/save-openid`, {
                openid: openid
            });
            if (response.data.success) {
                console.log('OpenID已成功保存到后端数据库');
            } else {
                console.error('后端保存OpenID失败', response.data.message);
            }
        } catch (error) {
            handleApiError(error,'保存OpenID时发生错误');
        }
    };
    // 创建订单 - 3
    const createOrder = async function (orderInfo: OrderInfo) {
        const modifiedOrderInfo = {...orderInfo};
        return axios.post(`${CONFIG.API}l_order/create-order`, modifiedOrderInfo, {headers: {'Content-Type': 'application/json' }})
            .then((response) => {
                console.log("===========【createOrder数据】response.data=======================");
                console.log(response.data);
                const _token = response.data.token;
                const _tradeNo = response.data.tradeNo;

                if(_token) {
                    //存token
                    Cookies.set('token', _token, { expires: 1/3 });
                }else {
                    console.log("后端接口中无token值。");
                };

                if(_tradeNo) {
                    //存tradeNo
                    Cookies.set('tradeNo', _tradeNo, { expires: 1/3 });
                }else {
                    console.log("后端接口中无tradeNo值。");
                };

                return response.data;

            }).catch((error) => {
                handleApiError(error, '创建订单失败: ');
            });
    };


//微信支付
const initiateWeChatPay = async function (products: ICartProduct[], total: ICartTotal) {
    try {
        const selectedProducts = getSelectedProducts(products);
        console.log("===============【已选商品】selectedProducts======================");
        console.log(selectedProducts);

        if (selectedProducts.length === 0) {throw new Error('没有选定的产品进行结算');};
        //订单数据
        const _openid = Cookies.get('openid');
        const _code = Cookies.get('code');
        const orderInfo: OrderInfo = {
            openid: _openid,
            items: selectedProducts,
            total: total.totalPrice
        };
        //订单数据存入状态
        // setOrderInfo(orderInfo);
        // 3. 创建订单
        await createOrder(orderInfo);


        const goodsStr = T.makeGoodsStr(selectedProducts) || '田小菜-消费';
        //支付
        await payByOa(_code, total, _openid, goodsStr); //【步骤三】使用同一个code进行支付

    } catch (error) {
        setIsPaying(false);
        handleApiError(error, '支付进程失败: ');
    } finally {
        setIsPaying(false);
    }
};


//PC支付
const generateQRCodeForPayment = function () {alert("PC扫码支付！")};

//“添加收货地址”点击事件：addAddr
//fetchData()
const fetchData = async () => {
        try {
            const _url = window.location.href;
            const response = await axios.get(`${CONFIG.API}get-addr-param`, { params: { url: _url } });
            if (response.data && response.data.success) {
                setWxConfigData(response.data);
                callAddrWin(response.data);
                // setIsReadyForPayment(true);
                setIsButtonDisabled(false);
            } else {
                console.error('后端返回的数据表明未成功获取参数');
                setIsButtonDisabled(true);
            }
        } catch (error) {
            console.error(error);
            setIsButtonDisabled(true);
        }
    };

const addAddr = useCallback(() => {
        setIsButtonDisabled(true);
        fetchData();
    }, []);
//收获地址存MYSQL
const saveAddrToServer = async (data:AddressInfo) => {
    // alert(`JSON.stringify(data)【6】：${JSON.stringify(data)}`);
    try {
        const response = await axios.post(`${CONFIG.API}l_customer_address/save-address`,  JSON.stringify(data), {
            headers: {'Content-Type': 'application/json'}
        });
        // alert(`状态: ${response.status}, 消息: ${response.data.message}, 数据: ${response.data.data}`);

        if (response.data.success) {
            // console.log('收货地址已成功保存到后端数据库');
            alert('添加地址成功');
        } else {
            console.error('后端保存收货地址失败', response.data.message);
        }
    } catch (error:any) {
        // handleApiError(error, '保存收货地址时发生错误');
        alert(`保存收货地址时发生错误: ${error.message}`);
    }
};

//callAddrWin()
const callAddrWin = function(wxConfigData:any) {
        //@ts-ignore - 忽略因为当前@types/库未定义wx.config方法的错误
        wx.config({
            debug: false,
            appId: wxConfigData.appID || "wxe7327d0b2230cf64",
            timestamp: wxConfigData.timeStamp,
            nonceStr: wxConfigData.nonceStr,
            signature: wxConfigData.signature,
            jsApiList: ['checkJsApi', 'openAddress',]
        });
        //@ts-ignore - 忽略因为当前@types/库未定义wx.ready方法的错误
        wx.ready(function() {
            //@ts-ignore - 忽略因为当前@types/库未定义wx.openAddress方法的错误
            wx.openAddress({
                success: async function(res:any) {
                    const _addressData = {
                        countryName: res.countryName,
                        provinceName: res.provinceName,
                        cityName: res.cityName,
                        detailInfo: res.detailInfo,
                        postalCode: res.postalCode,
                        userName: res.userName,
                        telNumber: res.telNumber,
                        customer_id: null as null | number
                    };
                    const _wechat = Cookies.get('openid');
                    const _customerId = await getCustomerIdByWechat(_wechat);
                    if(_customerId){_addressData.customer_id = _customerId;}
                    console.log('Address data:', _addressData);


                    //存地址入context
                    //setAddressInfo(addressData);

                    //保存地址信息到MYSQL
                    await saveAddrToServer(_addressData);

                   setIsReadyForPayment(true);
                },
                cancel:function(){
                    console.log("cancel");
                    setIsReadyForPayment(false);
                },
                fail:function(){
                    console.log("fail");
                    setIsReadyForPayment(false);
                },
                complete:function (){
                    console.log("complete,地址选择都会执行！");
                }
            });
        });
        //@ts-ignore - 忽略因为当前@types/库未定义wx.error方法的错误
        wx.error(function(res) {
            // 处理配置失败的情况
            console.log('微信配置失败: ' + JSON.stringify(res));
            setIsReadyForPayment(false);
        });
    };

const payNow = useCallback((products: ICartProduct[], total: ICartTotal) => {
    if (!isReadyForPayment) {
        alert("请先添加收货地址！");
        console.log("支付数据未就绪");
        return;
    }
    setIsButtonDisabled(true);

    if (T.detectPlatform() === 'weixin') {
        initiateWeChatPay(products, total);
    } else {
        generateQRCodeForPayment();
    }
}, [isReadyForPayment, wxConfigData, handlePaymentSuccess]);

    return (
        <S.Container isOpen={isOpen}>
            <S.CartButton onClick={handleToggleCart}>
                {isOpen && total.productQuantity > 0 ? (
                    <span>X</span>
                ) : (
                    <S.CartIcon style={{backgroundImage: `url(${CONFIG.CDN}cart-icon.png)`}}>
                        <S.CartQuantity title="Products in cart quantity">
                            {total.productQuantity}
                        </S.CartQuantity>
                    </S.CartIcon>
                )}
            </S.CartButton>
            {isOpen && (
                <S.CartContent>
                    <S.CartContentHeader>
                        <S.CartIcon style={{backgroundImage: `url(${CONFIG.CDN}cart-icon.png)`}}>
                            <S.CartQuantity>{total.productQuantity}</S.CartQuantity>
                        </S.CartIcon>
                        <S.HeaderTitle>购物车</S.HeaderTitle>
                    </S.CartContentHeader>
                    <CartProducts products={products}/>
                    <S.CartFooter>
                        <S.Sub>共计：</S.Sub>
                        <S.SubPrice>
                            <S.SubPriceValue>{`${total.currency_format} ${formatPrice(total.totalPrice, total.currency_id)}`}</S.SubPriceValue>
                            <S.SubPriceInstallment>
                                {total.discount ? (
                                    <span className="shine">
                                        {`充99抵200，享受买任一送一(原味)。`}
                                    </span>
                                ) : null}
                            </S.SubPriceInstallment>
                        </S.SubPrice>
                        <S.AddAddrButton
                           onClick = {(e)=>{
                               e.preventDefault();
                               addAddr();
                           }}
                           disabled={isButtonDisabled}
                        >
                            添加收货地址
                        </S.AddAddrButton>
                        <S.CheckoutButton
                            onClick={(e) => {
                                e.preventDefault();
                                payNow(products, total);
                            }}
                            disabled={!isReadyForPayment}
                            autoFocus>
                            立即支付
                        </S.CheckoutButton>

                    </S.CartFooter>
                </S.CartContent>
            )};
            {isNullModalOpen && (
                <Modal id="carttsx-null" onClose={handleNullClose}>
                    购物车为空 :(
                </Modal>
            )};
            {isQrOpen && (
                <Modal id="carttsx-qr" onClose={handleQrClose}>
                    {`小计: ${total.currency_format} ${formatPrice(total.totalPrice, total.currency_id)}`}
                    <QRCode
                        value="weixin://wxpay/bizpayurl?pr=vG8kOxIzz"
                        size={128}
                        fgColor="#2D2926"
                        bgColor="#ffffff"
                        level="H"
                    />
                </Modal>
            )};
            {isPayResOpen && (
                <Modal id="carttsx-pay" onClose={doPayResClose}>
                    {payResult}
                </Modal>
            )};


        </S.Container>
    );
};

export default Cart;
