import styled from 'styled-components/macro';

export const Container = styled.div``;

export const TwoColumnGrid = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1200px;
  margin: 110px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
    breakpoints.tablet}) {
    grid-template-columns: 1fr 4fr;
    margin-top: 88px;
  }
`;

export const Side = styled.div`
  display: grid;
  justify-content: center;
  padding: 0 10px 10px 10px;
  box-sizing: border-box;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    align-content: baseline;
  }
`;

export const Main = styled.main`
    border-top:1px solid ${({ theme }) => theme.colors.black};
    border-bottom:1px solid ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.antiquewhite};
    border-radius: 2px;
    margin-bottom:68px;
`;

export const MainHeader = styled.main`
  display: block;
  grid-template-columns: 1fr 1fr;
  justify-content: end;
  padding: 0 15px;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
    p{
      display: inline-block;
      width: 100%;
      text-align: right;
    }
  }

`;
