import axios from 'axios';
import { IGetProductsResponse } from 'models';

const isProduction = process.env.NODE_ENV === 'production';
export const getProducts = async () => {
    let response: IGetProductsResponse | any; // 允许任何类型，以便于处理不同格式的数据

    if (isProduction) {

        try {
            //产品数据【B. 线上或数据（来自mysql）】
            const res = await axios.get(`${process.env.REACT_APP_API_URL}l_products/`);
            // 将 B 数据格式转换为 C 数据格式
            response = { data: { products: res.data } };
        } catch (err) {
            console.error(err);
            // 发生错误时，使用 C 数据格式的空数组作为默认值
            response = { data: { products: [] } };
        }
    } else {
        //产品数据【A. 线上死数据】
        response = await axios.get('https://react-shopping-cart-67954.firebaseio.com/products.json');
        // A 处的数据已经是我们期望的格式，所以不需要额外处理

        // 产品数据【C. 本地死数据】
        // 直接从本地文件加载的数据假设已经是 C 数据格式
        // response = require('static/json/products.json');
    }

    // 由于所有情况下 response 都被处理为了统一的格式
    // 直接解构出 products 数组
    const products = response.data.products || [];

    console.log('&&&&&&&&&&&&', products); // 这里应该能正确输出 products 数组
    return products;
};
