import {useProducts} from 'contexts/products-context';
import * as S from './style';
import axios from "axios";
import {getSortedUniqueTypes} from '../../utils/getSortedUniqueTypes';

const isProduction = process.env.NODE_ENV === 'production';
let availableSizes = [''];
const ruleObj: Record<string, string> = {
    "yw": "冰淇凌【ෆᩚ原味】",
    "cm": "冰淇凌【草莓】",
    "mc": "冰淇凌【抹茶】",
    "qkl": "冰淇凌【巧克力】",
    "bc": "冰淇凌【缤彩⁺】"
};
// 反向映射规则，用于将中文标签转换为英文标签
const reverseRuleObj: Record<string, string> = Object.fromEntries(
    Object.entries(ruleObj).map(([key, value]) => [value, key])
);

if (isProduction) {
    //availableSizes = ['QD','XS', 'S', 'M', 'ML', 'L', 'XL', 'XXL'];
    axios.get(`${process.env.REACT_APP_API_URL}l_products/`)
        .then((res) => {
            console.log("=====production环境=====");
            getSortedUniqueTypes(res.data);
            // console.log(getSortedUniqueTypes(res.data));
            availableSizes = getSortedUniqueTypes(res.data);
            // availableSizes =['QD','XS', 'S', 'M', 'ML', 'L', 'XL', 'XXL']
        })
        .catch((err) => {
            console.log(err);
        });
} else {

    availableSizes = ['QD', 'XS', 'S', 'M', 'ML', 'L', 'XL', 'XXL'];
    console.log("=====不在production环境=====");
}

export {availableSizes};

const Filter = () => {
    const {filters, filterProducts} = useProducts();
    const selectedCheckboxes = new Set(filters);

    const toggleCheckbox = (chineseLabel: string) => {
        // 将中文标签转换为英文标签
        const label = reverseRuleObj[chineseLabel] || chineseLabel;
        if (selectedCheckboxes.has(label)) {
            selectedCheckboxes.delete(label);
        } else {
            selectedCheckboxes.add(label);
        }
        ;

        const filters = Array.from(selectedCheckboxes) as [];
        console.log("===============filter.tsx  filters line:39==================");
        // console.log(filters);

        //处理filters数组过滤产品关键函数
        filterProducts(filters);
    };

    const createCheckbox = (label: string) => {
        const chineseLabel = ruleObj[label] || label;
        return (
            <S.Checkbox label={chineseLabel} handleOnChange={toggleCheckbox} key={label}/>
        );
    };

    const createCheckboxes = () => availableSizes.map(createCheckbox);

    return (
        <S.Container>
            <S.Title>请选购:</S.Title>
            {createCheckboxes()}
        </S.Container>
    );
};
export default Filter;