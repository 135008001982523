import styled from 'styled-components/macro';

export const ModalContainer = styled.div`
  display: flex;
  max-width: 380px;
  min-height: 380px;
  aspect-ratio: 3 / 4;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.grayccc};
  background: white;
  border-radius: 6px;
  flex-direction: column; 
  justify-content: center; // 垂直居中
  align-items: center; // 水平居中
  gap: 16px; // 子元素之间的间隔
  
  button{
    display: inline-block;
    width: 68px;
    height: 38px;
    line-height: 38px;
    background-color: #07C160;
    border: none;
    border-radius: 2px;
    color: rgba(255,255,255,1);
    font-size:14px;
    font-weight: normal;
    text-align: center;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    /* 激活状态 */
    &:active {
      opacity: 0.6;
    }

    /* 禁用状态 */
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    /* 微信登录按钮特有样式 */
    &:before {
      content: '';
      display: block;
      font-size: 10px;
      font-weight: normal;
      white-space: nowrap;
    }

    span {
      display: none;
    }
  }

  // 在PC端调整样式
  @media only screen and (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
    width: auto; // PC端可能不需要宽度为90%
    max-width: 600px; // 可以设定一个更大的最大宽度
    padding: 60px; // 在大屏幕上通常可以有更大的内边距
  }

`;

export const ModalBackground = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(1,1,1,0.5);
  backdrop-filter: blur(5px);
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 199;

  // 在移动端可能不需要磨砂效果或者需要减少模糊值
  @media only screen and (max-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
    backdrop-filter: blur(3px);
  }
`;
