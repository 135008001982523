//文件信息：...components/Products/Product.tsx
import { IProduct } from 'models';
import Product from './Product';
import * as S from './style';

interface IProps {
  products: IProduct[];
}

const Products = ({ products }: IProps) => {
  // console.log("******************Products.tsx line:11 products ********************");
  // console.log(products);
  return (
    <S.Container>
      {products?.map((p) => (
        <Product product={p} key={p.sku} />
      ))}
    </S.Container>
  );
};
export default Products;
